<template>
  <div>
    <div class="title">{{ $t("baseInfo") }}</div>
    <el-form ref="form" :model="form" :label-width="language === 'zh-cn' ? '200px' : '270px'">
      <el-form-item :label="$t('supplierNameColumn')">
        <span>{{ form.name || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessAddress')">
        <span>{{ form.officeAddress || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('theRegisteredCapital')">
        <span>{{ (Number(form.registeredCapital) / $enums.UNIT_MILLION).toFixed(2) || 0 }} {{ $t("millionDollars") }}</span>
      </el-form-item>
      <el-form-item :label="$t('registeredAddress')">
        <span>{{ form.registeredAddress || "--" }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalRepresentative')">
        <span>{{ form.directorName || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalPersonId')">
        <span>{{ form.directorCertNumber || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('legalPersonPhone')">
        <span>{{ form.directorMobileNumber || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessMail')">
        <span>{{ form.corporateEmail || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('socialCreditCode')" style="width: 100%;">
        <span>{{ form.registeredNumber || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('businessScope')" style="width: 100%;">
        <span>{{ form.businessScope || "" }}</span>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.certificateOfIncorporation')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="goLink(form.coi)">
          {{
          form.coi &&
          form.coi.split("/")[form.coi.split("/").length - 1]
          }}
        </el-link>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.businessRegistration')" style="width: 100%">
        <el-link :underline="false" type="primary" @click="goLink(form.businessRegistration)">
          {{
          form.businessRegistration &&
          form.businessRegistration.split("/")[form.businessRegistration.split("/").length - 1]
          }}
        </el-link>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.legalHoldingStructure')" style="width: 100%">
        <el-link :underline="false" v-if="form.legalHoldingStrcture" type="primary" @click="goLink(form.legalHoldingStrcture)">
          {{
          form.legalHoldingStrcture.split("/")[form.legalHoldingStrcture.split("/").length - 1]
          }}
        </el-link>
        <span v-else>--</span>
      </el-form-item>
      <el-form-item :label="$t('businessLogo')" style="width: 100%;">
        <!-- <el-image :src="form.companyLogo" style="width: 200px;"></el-image> -->
        <el-image :src="form.companyLogo || ''">
          <div slot="error" class="image-slot"></div>
        </el-image>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("bankInformation") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="200px">
        <div>
            <el-form-item :label="$t('bankOfCityCountry')" label-width="200px">
                <span v-if="form.areaId">{{ getAreaName(form.areaId) }}</span>
                <span v-else></span>
            </el-form-item>
        </div>
        <el-form-item :label="$t('bankOfDeposit')">
            <div class="flex-row">
                <el-image v-if="form.receivingBankIcon" :src="form.receivingBankIcon" style="width: 18px; height: 18px; margin-right: 10px;"></el-image>
                <span v-if="language==='zh-cn'">{{ form.receivingBank }}</span>
                <span v-else>{{ form.receivingBankEn || form.receivingBank }}</span>
            </div>
        </el-form-item>
        <el-form-item :label="$t('companyCreate.swiftCode')" label-width="200px">
            <span>{{ form.swiftCode }}</span>
        </el-form-item>
      <el-form-item :label="$t('companyCreate.bankNumber')">
        <span>{{ form.bankAccountNumber }}</span>
      </el-form-item>
      <el-form-item :label="$t('companyCreate.bankAccount')" label-width="200px">
        <span>{{ form.accountHolderName }}</span>
      </el-form-item>
    </el-form>
    <div class="title">{{ $t("cooperationInfo") }}</div>
    <el-form ref="form" :model="form" style="width: 1100px" label-width="200px">
      <el-form-item :label="$t('certificateOfCooperation')" style="width: 100%;">
        <el-link
          v-if="form.certificateIncorporation"
          :underline="false"
          type="primary"
          @click="goLink(form.certificateIncorporation)"
        >{{ form.certificateIncorporation.split('/')[form.certificateIncorporation.split('/').length-1] }}</el-link>
        <span v-else>--</span>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 30px">
      <el-button class="btn-black" v-on:click="close">{{ $t("btnI18n.Close") }}</el-button>
    </div>
  </div>
</template>

<script>
import {getObjByKeyValue} from "@/utils";
export default {
  name: "supply-detail",
  data() {
    return {
      multipleSelection: [],
      onlineSure: true,
      form: {
        areaCode: "+86"
      },
        // bank update
        optionsCountry: [],
        // bank update
    };
  },
  mounted() {
      this.getAreaList();
    this.pageInit();
  },
  methods: {
      // bank update
      getAreaList() {
          let _this = this;
          this.optionsCountry = [];
          this.$axios.get("/v1/bank/area").then(res => {
              if (!res.code) {
                  _this.optionsCountry = res.data;
              }
          });
      },
      getAreaName(value) {
          if (!value) return "";
          if (!this.optionsCountry.length) return "";
          if (this.language === "zh-cn") {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).cnName;
          } else {
              return getObjByKeyValue(this.optionsCountry, "code", Number(value)).enName;
          }
      },
      // bank update
    goLink(url) {
      if (/.*(\.png|\.jpg|\.jpeg|\.gif|\.PNG|\.JPG|\.JPEG|\.GIF)$/.test(url)) {
        const image = new Image();
        image.src = url;
        const imgWindow = window.open(url);
        imgWindow.document.write(image.outerHTML);
      } else if (/.*(\.pdf|\.PDF)$/.test(url)) {
          let routeData = this.$router.resolve({
              query: {url:url},
              path:'/reviewPDF'
          });
          window.open(routeData.href, '_blank');
      } else {
          window.open(url);
      }
    },
    pageInit() {
      this.$axios.get('/v1/brand-owner/supplier-detail', { params: { id: this.$route.query.id } }).then(result => {
        if (!result.code) {
          this.form = result.data;
        } else {
          this.$message({ type: 'error', message: result.message });
        }
      });
    },
    close() {
      this.$router.push({ path: `/financing-manage` });
    }
  },
  computed: {
    language() {
      return this.$store.getters['auth/language'];
    }
  },
  watch: {
    language() {
      console.log(this.language);
    }
  }
};
</script>

<style lang="scss" scoped>
.el-form-item {
  width: 550px;
  display: inline-block;
  margin-bottom: 0;
}
</style>
